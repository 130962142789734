import React, { useEffect, useState } from 'react';
import './career.css';
import img from '../image/career.svg';
import img4 from '../image/Senior developer.svg';
import img2 from '../image/UI UX.svg';
import img3 from '../image/Intern Software Developer.svg';
import img1 from '../image/Senior System Architect.svg';
import img6 from '../image/Business Analyst.svg';
import img5 from '../image/Admin.svg';
import element2 from "../image/react logo.svg";



import { Link } from 'react-router-dom';

export const Career = () => {
    const [cards, setCards] = useState(Array(3).fill(false));



    const handleCardClick = (index) => {
        const updatedCards = cards.map((card, i) => (i === index ? !card : false));
        setCards(updatedCards);
    };

    const iconArray = [
        { img: img1, gap: '20px' },
        // { img: img2, gap: '15px' },
        // { img: img3, gap: '20px' },
        { img: img4, gap: '10px' },
        { img: img5, gap: '15px' },
        // { img: img6, gap: '20px' },
        // Add more icon objects with different gaps as needed
    ];

    const cardContent = [
        {
            title: 'Senior System Architect',
            extraContent: ' We are seeking an exceptional individual with a strategic mindset and deep technical expertise to join our innovative team, implementing the architectural vision of our systems.',

            description: 'Qualification : B.Sc.CS, B.Sc.CT ,MCA ',
            descriptions: ' Experience : 5 Years',
            button: 'Apply Now',
        },
        // {
        //     title: 'UI / UX Designer',
        //     description: 'Qualification : B.Sc.CS, B.Sc.CT.',
        //     descriptions: 'Experience : 0-2 Years.',
        //     extraContent: 'We are on the lookout for an exceptionally talented individual. You have the power to craft intuitive user interfaces that captivate and a profound grasp of user experience principles, seize this opportunity to redefine the way people interact with digital experiences.',
        //     button: 'Apply Now',
        // },
        // {
        //     title: 'Intern Software Developer',
        //     description: 'Qualification : B.Sc.CS, B.Sc.CT, MCA',
        //     descriptions: 'Experience : 0-1 Years',
        //     extraContent: ' We have an exciting opportunity for an Intern Software Developer to join our dynamic team. As an intern, you will gain valuable hands-on experience on real-world projects and collaborating with a talented group of professionals.',
        //     button: 'Apply Now',
        // },
        {
            title: 'Senior Software Developer(Full Stack)',
            description: 'Qualification : B.Sc.CS, B.Sc.CT ,MCA',
            descriptions: 'Experience : 5 Years.',
            extraContent: 'We have an incredible opportunity for a Senior Software Developer to join our exceptional team. You will lead the development process, collaborating with cross-functional teams to design, develop, and deploy robust software applications.',
            button: 'Apply Now',
        },
        {
            title: 'Admin',
            description: 'MBA (5 Years Experience)',
            descriptions: 'Experience :  5 Years.',
            extraContent: ' We are seeking a highly organized and proactive individual person, with exceptional administrative skills and thrive in a fast-paced environment, seize this opportunity to be the backbone of our operations.',
            button: 'Apply Now',
        },
        // {
        //     title: 'Business Analyst',
        //     description: 'Qualification : MCA',
        //     extraContent: 'We are in search of a highly skilled and Analytic person . Who possess the ability to unravel intricate business processes and translate them into effective solutions, seize this opportunity to empower organizations with your strategic insights.',
        //     descriptions: 'Experience : 5 Years.',
        //     button: 'Apply Now',

        // },

        // Add more card content objects as needed
    ];

    useEffect(() => {
        // Any additional JavaScript logic you need can be placed here.
    }, []); // Empty dependency array to run this code only once when the component mounts

    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h1 className='' style={{ marginTop: '150px' }}>Find Your Passion and Craft a Gratifying Career Journey with Us!</h1>
                        <p style={{ marginTop: '40px', textAlign: "justify" }}>We prioritize people and foster cooperation in our core values, dedicated to long-term success. Our main goal is harmonizing work and personal life while promoting inclusive communication, allowing diverse communication styles to thrive.</p>
                    </div>
                    <div className='col-md-6'>
                        <img src={img} width='100%' height='100%' alt='Logo not found' />
                    </div>
                </div>
                <hr></hr>
            </div>

            <div className='container my-5'>
                <div className='row '>
                    <div className='d-flex flex-column text-center align-items-center'>
                        <h1 style={{ fontSize: "50px" }}>Our Recent Job Openings</h1>

                    </div>
                </div>
            </div>
            <div className='container mb-5'>
                {/* <div className='backgroundElement6'>
                    <img width="68" height="68" src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/96/external-mongodb-a-cross-platform-document-oriented-database-program-logo-shadow-tal-revivo.png" alt="external-mongodb-a-cross-platform-document-oriented-database-program-logo-shadow-tal-revivo"/>
                </div>
                <div className='backgroundElement7'>
                    <img width="68" height="68" src="https://img.icons8.com/fluency/48/mysql-logo.png" alt="mysql-logo"/>
                </div>                */}
                <div className='backgroundElement2'>
                    <img src={element2} alt='reactLogo' width="16%"/></div>
                <div className='backgroundElement1'>
                    <img width="65" height="65" src="https://img.icons8.com/fluency/48/figma.png" alt="figma"/>
                </div>
                {/* <div className='backgroundElement3'>
                    <img width="60" height="60" src="https://img.icons8.com/fluency/96/azure-1.png" alt="azure-1"/>
                </div> */}
                <div className='backgroundElement4'>
                    <img width="65" height="65" src="https://img.icons8.com/dusk/64/php-logo.png" alt="php-logo"/></div>
                <div className='backgroundElement5'>
                    <img width="65" height="65" src="https://img.icons8.com/color/48/wordpress.png" alt="wordpress"/>
                </div>
                               
                <div className='backgroundElement8'>
                    <img width="65" height="65" src="https://img.icons8.com/arcade/64/html-5.png" alt="html-5"/>
                </div>
                <div className='backgroundElement9'>
                    <img width="70" height="70" src="https://img.icons8.com/color/48/nodejs.png" alt="nodejs"/>
                </div>
                {/* <div className='backgroundElement10'>
                    <img width="60" height="60" src="https://img.icons8.com/color/48/dell--v1.png" alt="dell--v1"/>
                </div> */}
                <div className='element'> 
                    
                </div>
                <div class="all">
                    <div class="cards d-flex justify-content-center row">
                        {cards.map((isOpen, index) => (
                            <div
                                key={index}
                                className={`card d-flex flex-row  ${isOpen ? 'open shadow-2 ' : 'shadow-1 '}`}

                            >
                                <div className='col-2 mb-5'>
                                    {iconArray[index] && (

                                        <img
                                            src={iconArray[index].img} // Use the specified image source for the card
                                            className="card-icon mb-5"
                                            alt="Icon" // Provide an alt text for accessibility
                                            style={{ marginRight: iconArray[index].gap, width: '80px', marginTop:"3%" }}
                                        />

                                    )}
                                </div>
                                <div className='col-10 '>
                                    <h3 style={{  marginTop: "3%" }}>{cardContent[index].title}</h3>
                                    <p style={{ }}>{cardContent[index].description} </p>
                                    <p style={{  }}>{cardContent[index].descriptions}

                                        {isOpen ? "" : <Link className="showcontent" onClick={() => handleCardClick(index)} style={{ textDecoration: "none", position: "absolute", right: "2%", bottom:"3%"}}>Read more</Link>}</p>
                                    {isOpen && (
                                        <div>
                                            {/* Display the content and button */}
                                            <p style={{marginBottom: '-3%', textAlign:'justify'}}>{cardContent[index].extraContent}</p>

                                            <Link >
                                                <button className="btn rounded btn_nav " style={{ margin: "5%" }}>
                                                    {cardContent[index].button}
                                                </button>
                                            </Link>
                                            {isOpen ? <Link className="showcontent" onClick={() => handleCardClick(index)} style={{ textDecoration: "none", position: "absolute", bottom: "10%", right: "2%" }}>Read less</Link> : ""}
                                        </div>
                                    )}
                                </div>

                            </div>

                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}