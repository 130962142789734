import React, { useState } from 'react';
import HeroSection from '../image/HeroSection.jpg';

import Training from '../image/TechnicalTraining.svg';
import Development from '../image/sw development.svg';
import testing from "../image/testing.png";
import { animated} from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { useSpring } from 'react-spring';
import { Link } from 'react-router-dom';

function Home() {
  // Define a ref and state variable for the container section
  const [ref, inView] = useInView();
  const [animationTriggered, setAnimationTriggered] = useState(false);

  // Define animation for the container section
  const containerAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(100px)',
    config: { duration: 1000 },
    // You can adjust the animation config as needed
  });

  // Trigger the animation when the container is in view
  if (inView && !animationTriggered) {
    setAnimationTriggered(true);
  }

  return (
    <>
      <div className='position-relative content-container' >
        <img src={HeroSection} alt="herosection" className='img-fluid mt-1 '  width="100%"  />
        <div className='text-center content'>
          <h1>
            <b
              className=''
              style={{
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Libre Baskerville',
                fontSize: '50px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
              }}
            >
              Welcome To ThoughtForce
            </b>
          </h1>
          <br />
          <h4
            className=''
            style={{
              color: '#808080',
              textAlign: 'center',
              fontSize: '26px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
            }}
          >
            World of Opportunity for Technical Enthusiasts!
          </h4>
          <br />
          <Link to="/contact"><button className='btn p-2' style={{ background: '#A2B0FD', color:"white"}}>
            Contact Us
          </button></Link>
        </div>
      </div>

      <div >
        <div className='container py-4' ref={ref}>
          <animated.h1
            className='text-center'
            style={{
              ...containerAnimation,
              color: '#000',
              textAlign: 'center',
              fontFamily: "'Fanwood Text', serif",
              fontSize: '48px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              
            }}
          >
            Why ThoughtForce
          </animated.h1>
          <div className='d-flex justify-content-center'>
            <animated.h4
              className='text-center w-100 mt-3'
              style={{
                ...containerAnimation,
                color: '#000',
                textAlign: 'center',
                fontFamily: "'Fanwood Text', serif",
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: '200',
                letterSpacing: '0.1ch',
                lineHeight: 'normal',
              }}
            >
              Every thought is a catalyst, setting forces into motion. These forces drive actions, leading to tangible outcomes. Understanding this cycle empowers us to shape our destiny through mindful thinking. Harness the force of your thoughts to create a better future.
            </animated.h4>
          </div>

          <div className='row d-flex justify-content-around'>
            <div className='col-md-4 p-4 mt-3'>
              <img src={Training} alt ="training" className='w-100 image-pickup' />
              <animated.h3
                className='text-center mt-2'
                style={{
                  ...containerAnimation,
                  color: '#000',
                  textAlign: 'center',
                  fontFamily: "'Fanwood Text', serif",                 
                  fontStyle: 'normal',
                  fontWeight: '300',
                  fontSize:"22px",
                  lineHeight: 'normal',
                }}
              >
                TED (Technical Expertise Development) 
              </animated.h3>
            </div>
            <div className='col-md-4 p-4 mt-3'>
              <img src={Development} alt="development" className='w-100  image-pickup' />
              <animated.h3  
                className='text-center mt-2'
                style={{
                  ...containerAnimation,
                  color: '#000',
                  textAlign: 'center',
                  fontFamily: "'Fanwood Text', serif",
                  fontStyle: 'normal',
                  fontSize:"22px",
                  fontWeight: '300',
                  lineHeight: 'normal',
                }}
              >
                Software Development
              </animated.h3>
            </div>
            <div className='col-md-4 p-4 mt-3'>
              <img src={testing} alt="testing" className='w-100  image-pickup p-3' />
              <animated.h3  
                className='text-center mt-2'
                style={{
                  ...containerAnimation,
                  color: '#000',
                  textAlign: 'center',
                  fontFamily: "'Fanwood Text', serif",
                  fontStyle: 'normal',
                  fontSize:"22px",
                  fontWeight: '300',
                  lineHeight: 'normal',
                }}
              >
                PAT (Product & Application Testing)
              </animated.h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
