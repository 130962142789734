import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import logo from "../image/thoughtforceLogo.png";
import "./Login.css";
import { useEffect, useState } from 'react';

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';




// function Header() {
//     const [headerFixed, setHeaderFixed] = useState(false);

//     useEffect(() => {
//       const handleScroll = () => {
//         if (window.scrollY > 100) { 
//           setHeaderFixed(true);
//         } else {
//           setHeaderFixed(false);
//         }
//     };  
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//         window.removeEventListener('scroll', handleScroll);
//       };
//     }, []);

//   return (
//     <Navbar expand="lg" sticky="top" className={headerFixed && "nav_fixed"}>
//       <Container fluid  >
//         <Navbar.Brand href="/"><img src={logo} alt='logo' width="200px"/></Navbar.Brand>
//         <Navbar.Toggle aria-controls="navbarScroll" />
//         <Navbar.Collapse id="navbarScroll">
//           <Nav
//             className="me-auto my-2 my-lg-0  mx-auto"
//             style={{ maxHeight: '100px',}}
//             navbarScroll
//           >
//             {/* <NavLink href="/" className="link p-3">Home</NavLink>
//             <NavLink href="/aboutus" className="link p-3">About us</NavLink>
//             <NavLink href="/service" className="link p-3">Services</NavLink>
//             <NavLink href="/career" className="link p-3">Career</NavLink>
//             <NavLink href="/contact" className="link p-3">Contact us</NavLink> */}
//             <NavLink to="/" className="link p-3">Home</NavLink>
//             <NavLink to="/aboutus" className="link p-3">About us</NavLink>
//             <NavLink to="/service" className="link p-3">Services</NavLink>
//             <NavLink to="/career" className="link p-3">Career</NavLink>
//             <NavLink to="/contact" className="link p-3">Contact us</NavLink>
//           </Nav>
//           <Form className="d-flex">            
//             <button className=' btn btn_nav me-2'>Sign In</button>
//             <button className=' btn btn_nav'>Sign Up</button>
//           </Form>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }
// export default Header;




// const pages = ['Products', 'Pricing', 'Blog'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export default function ResponsiveAppBar() {

  const [headerFixed, setHeaderFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setHeaderFixed(true);
      } else {
        setHeaderFixed(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    handleClick();
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    handleClick();
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
}

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    <AppBar position="sticky" className={(headerFixed && "nav_fixed") || "bg-transparent"} style={{ fontFamily: "Libre Baskerville', serif" }}>
      <Container fluid maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
             <Link to="/" onClick={handleClick} className="link "><img src={logo} alt='logo' width="145px" /></Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: 'gray' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}

            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/" onClick={handleClick} className="link "><Typography textAlign="center">Home</Typography></Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/aboutus" onClick={handleClick} className="link "><Typography textAlign="center">About us</Typography></Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/service" onClick={handleClick} className="link " ><Typography textAlign="center">Services</Typography></Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/career" onClick={handleClick} className="link "><Typography textAlign="center">Career</Typography></Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/contact" onClick={handleClick} className="link "><Typography textAlign="center">Contact us</Typography></Link>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logo} alt='logo' width="200px" />

          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', }, justifyContent: "right" }} >

            <Link to="/"  className="link p-3" onClick={handleCloseNavMenu}>
              Home
            </Link>
            <Link to="/aboutus" className="link p-3" onClick={handleCloseNavMenu}>
              About us
            </Link>
            <Link to="/service" className="link p-3" onClick={handleCloseNavMenu}>
              Services
            </Link>
            <Link to="/career" className=" link p-3" onClick={handleCloseNavMenu}>
              Career
            </Link>
            <Link to="/contact" className="link p-3" onClick={handleCloseNavMenu}>
              Contact us
            </Link>

          </Box>
{/* 
          <Box sx={{ flexGrow: 0, marginRight: 0 }}>
            <button className=' p-1 btn btn_nav me-2'>Sign In</button>
            <button className=' p-1 btn btn_nav'>Sign Up</button>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}


