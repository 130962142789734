import React, { useState } from 'react';
import HeroSection from '../image/AboutUsWall 1 (1).jpg';
import Card1 from '../image/Card (5).jpg';
import Card2 from '../image/Frame 30.png';
import Card3 from '../image/Card (3).jpg';
import Card4 from '../image/Card (4).jpg';
import Card5 from '../image/Card (1).svg';

import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';


function AboutUs() {
  const [animationTriggered, setAnimationTriggered] = useState(false);

  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();
  const [ref5, inView5] = useInView();
  // Add more refs and inView variables for other sections as needed

  // Define animations for each section
  const animation1 = useSpring({
    opacity: inView1 ? 1 : 0,
    transform: inView1 ? 'translateY(0px)' : 'translateY(150px)',
    config: { duration: 1000 },
    onRest: () => setAnimationTriggered(true),
  });

  const animation2 = useSpring({
    opacity: inView2 ? 1 : 0,
    transform: inView2 ? 'translateY(0px)' : 'translateY(150px)',
    config: { duration: 1000 },
    onRest: () => setAnimationTriggered(true),
  });

  const animation3 = useSpring({
    opacity: inView3 ? 1 : 0,
    transform: inView3 ? 'translateY(0px)' : 'translateY(150px)',
    config: { duration: 1000 },
    onRest: () => setAnimationTriggered(true),
  });

  const animation4 = useSpring({
    opacity: inView4 ? 1 : 0,
    transform: inView4 ? 'translateY(0px)' : 'translateY(150px)',
    config: { duration: 1000 },
    onRest: () => setAnimationTriggered(true),
  });

  const animation5 = useSpring({
    opacity: inView5 ? 1 : 0,
    transform: inView5 ? 'translateY(0px)' : 'translateY(150px)',
    config: { duration: 1000 },
    onRest: () => setAnimationTriggered(true),
  });
  return (
    <>
      <div className='position-relative content-container'>
        <img src={HeroSection} className='img-fluid' alt='Hero ' />
        <div className='text-center Aboutcontent'>
          <h1 style={{
            color: "#000",
            textAlign: "center",
            // fontFamily: "Libre Baskerville",
            fontSize: "48px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal"
          }}>About Us
          </h1>
          <div className='d-flex justify-content-center'>
            <p className='w-75' style={{
              color: "#000",
              textAlign: "justify",
              fontSize: "17px",
              fontStyle: "normal",
              lineHeight: "1.5",
              letterSpacing: '0.1ch',
              
            }}>
              
              At ThoughtForce, Problem-Solving skills of technologies stand within the scope of boundaries of data input, however, our crew in ThoughtForce offer solutions with mindfulness that are beyond the limits of expectantions.
              {/* we harness technical brilliance, transforming untapped potential into a formidable force. We're committed to innovation, offering comprehensive training to tech-savvy individuals. ThoughtForce is where tech wizards become holistic leaders, breaking boundaries. Join us in shaping a future where brilliance thrives */}
            </p>
          </div>
        </div>
      </div>
      <div className='container-fluid px-5'  >
        <animated.div
          className='row py-3 d-flex align-items-center'
          style={animation1}
          ref={ref1} // Attach the ref to the section
        >
          <div className='col-md-6 ' >
            <img
              src={Card1}
              className='img-fluid mt-4 '
              alt='Hero '
              style={{borderRadius:"30px"}}
            />
          </div>
          <div className='col-md-6 '>
            <h2
              style={{
                color: '#000',
                textAlign: 'center',                
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
              }}
            >
              In a tech-driven realm
              <span style={{ color: "#9E509B" }}>.</span>
              <span style={{ color: "#9e3adf" }}>.</span>
              <span style={{ color: "#FFB347" }}>.</span>
            </h2>
            <h5
              style={{
                color: '#808080',
                textAlign: 'center',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
              }}
            >
              Mastery of code, but words remained uncharted
            </h5>
          </div>
        </animated.div>
        <animated.div
          className='row py-3 d-flex align-items-center'
          style={animation2}
          ref={ref2} // Attach the ref to the section
        >
          <div className='col-md-6'>
            <h2 style={{
              color: "#000",
              textAlign: "center",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal"
            }}>Brilliance in circuits, Flounder in imparting
              <span style={{ color: "#9E509B" }}>.</span>
              <span style={{ color: "#9e3adf" }}>.</span>
              <span style={{ color: "#FFB347" }}>.</span>
            </h2>
            <h5 style={{
              color: "#808080",
              textAlign: "center",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal"
            }}>Ideas flowed like code, but conversations hit barriers</h5>
          </div>
          <div className='col-md-6'>
            <img src={Card2} className='img-fluid mt-4 ' alt='Hero '  style={{borderRadius:"30px"}}/>
          </div>
        </animated.div>

        <animated.div
          className='row py-3 d-flex align-items-center'
          style={animation3}
          ref={ref3} // Attach the ref to the section
        >
          <div className='col-md-6'>
            <img src={Card3} className='img-fluid mt-4 ' alt='Hero ' style={{borderRadius:"30px"}} />
          </div>
          <div className='col-md-6'>
            <h2 style={{
              color: "#000",
              textAlign: "center",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal"
            }}>ThoughtForce recognized untapped genius
              <span style={{ color: "#9E509B" }}>.</span>
              <span style={{ color: "#9e3adf" }}>.</span>
              <span style={{ color: "#FFB347" }}>.</span>
            </h2>
            <h5 style={{
              color: "#808080",
              textAlign: "center",
              marginTop: "0%",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal"
            }}>A sanctuary where tech wizards thrive</h5>
          </div>
        </animated.div>


        <animated.div
          className='row py-3 d-flex align-items-center'
          style={animation4}
          ref={ref4} // Attach the ref to the section
        > <div className='col-md-6'>
            <h2 style={{
              color: "#000",
              textAlign: "center",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal"
            }}>Enlightened training, skills honed to precision
              <span style={{ color: "#9E509B" }}>.</span>
              <span style={{ color: "#9e3adf" }}>.</span>
              <span style={{ color: "#FFB347" }}>.</span>
            </h2>
            <h5 style={{
              color: "#808080",
              textAlign: "center",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal"
            }}>From tech maestro to a holistic force</h5>
          </div>
          <div className='col-md-6'>
            <img src={Card4} className='img-fluid mt-4' alt='Hero ' style={{borderRadius:"30px"}} />
          </div>
        </animated.div>

        <animated.div
          className='row py-3 d-flex align-items-center'
          style={animation5}
          ref={ref5} // Attach the ref to the section
        ><div className='col-md-6'>
            <img src={Card5} className='img-fluid mt-4' alt='Hero ' style={{borderRadius:"30px"}}/>
          </div>
          <div className='col-md-6'>
            <h2 style={{
              color: "#000",
              textAlign: "center",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal"
            }}>With newfound confidence, they pioneer
              <span style={{ color: "#9E509B" }}>.</span>
              <span style={{ color: "#9e3adf" }}>.</span>
              <span style={{ color: "#FFB347" }}>.</span>
            </h2>
            <h5 style={{
              color: "#808080",
              textAlign: "center",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal"
            }}>Together, they flourish as the unstoppable ThoughtForce</h5>
          </div>

        </animated.div>

      </div>
    </>
  )
}

export default AboutUs