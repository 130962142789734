import React from "react";
import contact from '../image/contact.png';

const Contact = () => {
    return (
        <div className=" container">
            <div className="row d-flex justify-content-evenly align-items-center ">
                <div className="col-md-6  d-flex justify-content-center  " >
                    <img src={contact} alt="contact" width="90%" />
                </div>
                <div className="col-md-6 mt-5  mb-5">
                    <h1 className="mb-3">Contact Us</h1>
                    <p className="mb-5">For general queries, sales and partner ships please drop a Email, We will reach you.</p>
                    <a className="shadow text-center px-5 py-3 text-decoration-none text-white rounded " style={{ backgroundColor: "#808080", border: "none" }} href="https://mail.google.com/mail/?view=cm&fs=1&to=ayyappan@thoughtforce.io" target="_blank">
                        Email
                    </a>
                </div>
            </div>
            <hr></hr>
            <div>
                <h1 className="text-center  mb-5 pt-3"> Office Location</h1>
                <div className="row d-flex justify-content-around ">
                    <div className="col-md-6 d-flex justify-content-center mb-3">

                        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d279955.99723496573!2d55.17365742261014!3d25.114013407263876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sThoughtForce%20LLC%2C%20Sharjah%20media%20city%2C%20Sharjah%20%2C%20UAE!5e0!3m2!1sen!2sin!4v1707733971822!5m2!1sen!2sin" width="400px" height="400px" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="rounded shadow"></iframe>

                    </div>
                    <div className="col-md-6 mt-5  mb-3" style={{ paddingLeft: "5%" }}>
                        {/* <p className="mb-2" ><img width="35" height="35" src="https://img.icons8.com/plasticine/100/business-buildings.png" alt="business-buildings" /> &nbsp;India Address: 160, Lakshmi Mills Colony, P.N Palayam, Coimbatore - 641037 </p> */}
                        <p className="mb-2 text-wrap" ><img width="35" height="35" src="https://img.icons8.com/plasticine/100/business-buildings.png" alt="business-buildings" /> &nbsp; UAE Address: ThoughtForce LLC, Sharjah media city, Sharjah , UAE </p>
                        <p className="mb-2 text-wrap" ><img width="35" height="35" src="https://img.icons8.com/plasticine/100/business-buildings.png" alt="business-buildings" /> &nbsp; USA Address: Coming soon...</p>



                        {/* <p className="mb-2" ><img width="45" height="45" src="https://img.icons8.com/clouds/100/place-marker.png" alt="place-marker"/> PSG-STEP Innosphere, PSG college of technology <span style={{marginLeft:'50px'}}>Peelamedu, Coimbatore - 641004</span> </p> */}

                        {/* <p className="mb-2" ><img width="34" height="34" src="https://img.icons8.com/dusk/64/phone.png" alt="phone" /> &nbsp;&nbsp;India Mobile: +91 7010552020</p> */}
                        <p className="mb-2" ><img width="34" height="34" src="https://img.icons8.com/dusk/64/phone.png" alt="phone" /> &nbsp;&nbsp;UAE Mobile: +971 521830041</p>


                        <p className="mb-2" ><img width="50" height="50" src="https://img.icons8.com/clouds/100/new-post.png" alt="new-post" /><a className="text-dark text-decoration-none" href="https://mail.google.com/mail/?view=cm&fs=1&to=ayyappan@thoughtforce.io" target="_blank"><span  >  ayyappan@thoughtforce.io</span></a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;