import React, { useState } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom"

import Swal from 'sweetalert2';

const ResetPassword = () => {
    const [passShow, setPassShow] = useState(false);
    const [cpassShow, setCPassShow] = useState(false);
    const { id } = useParams();
    const { token } = useParams();
    console.log(id, token);

    const [error, setError] = useState({});
    const [inputValue, setinputValue] = useState({
        password: "",
        cpassword: ""
    });
    const [isValid, setIsValid] = useState({
        cpassword: false,
        password: false,
    });

    const navigate = useNavigate();
    const setVal = (e) => {
        // console.log(e.target.value);
        const { name, value } = e.target;

        setinputValue(() => {
            return {
                ...inputValue,
                [name]: value
            }
        })
        setIsValid((validState) => ({
            ...validState,
            [name]: value.trim() !== '',
        }));
    };


    const Reset = async (e) => {
        e.preventDefault();

        const { password, cpassword } = inputValue;
        const errorMessage = {};

        if (password === "") {
            errorMessage.password = "Password is required!"
        }
        else if (password.length < 8) {
            errorMessage.password = "Password must be 8 character!"
        }
        else if (!/[A-Z]/.test(password)) {
            errorMessage.password = "Password must contain at least one uppercase letter!";
        }
        else if (!/[a-z]/.test(password)) {
            errorMessage.password = "Password must contain at least one lowercase letter!";
        }
        else if (!/[0-9]/.test(password)) {
            errorMessage.password = "Password must contain at least one digit!";
        }
        else if (!/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password)) {
            errorMessage.password = "Password must contain at least one special character!";
        }
        if (cpassword === "") {
            errorMessage.cpassword = "Confirm password is required!"
        }
        else if (cpassword.length < 8) {
            errorMessage.cpassword = "Confirm password must be 8 character!"
        }
        if (password !== cpassword) {
            errorMessage.cpassword = "Password and Confirm password are not matching!"
        }
        setError(errorMessage);
        if (Object.keys(errorMessage).length === 0) {

            try {

                const data = await fetch(`http://localhost:8000/api/reset-password/${id}/${token}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        password,
                    })
                });

                const res = await data.json();
                console.log(res);

                if (res.status === 201) {

                    Swal.fire({
                        icon: "success",
                        title: res.msg,
                    });
                    setinputValue({ ...inputValue, cpassword: "", password: "" });
                    navigate("/forgotpassword");
                }
                else if (res.status === 401) {
                    Swal.fire({
                        icon: "error",
                        title: res.error,
                        text: "Sign Up Your Account!"
                    });
                }
                else if (res.status === 403) {
                    Swal.fire({
                        icon: "warning",
                        title: res.error,
                        text: "Try Again!"
                    });
                }
                else if (res.status === 500) {
                    Swal.fire({
                        icon: "info",
                        title: res.error,

                    });
                }

            }
            catch {
                Swal.fire({
                    icon: "info",
                    title: "502 Bad Gateway"
                });
            }
        }
    }

    return (
        <>
            <section>
                <div className="form_data d-flex flex-column " style={{ width: "30%" }}>
                    <form>
                        <div className="form_heading">
                            <h1>Reset Password</h1>
                        </div>
                        <div className="form_input">
                            <label htmlFor="password" className='mb-1'>Password <b style={{ color: "red" }}>*</b></label>
                            <div className="two">
                                <input type={!passShow ? "password" : "text"} value={inputValue.password} onChange={setVal} name="password" id="password" placeholder='********' className={(error.password && "input_error") || (isValid.password && "valid")} />

                                <div className="showpass" onClick={() => setPassShow(!passShow)}>
                                    {!passShow ? <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" style={{ color: "#c10a49" }}><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" fill='#c10a49' /></svg> : <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" style={{ color: 'rgb(193, 10, 73)' }}><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" fill='#c10a49' /></svg>}
                                </div>
                            </div>
                            {error.password ? <span style={{ color: "red", fontSize: "14px" }}>{error.password}</span> : ""}
                        </div>

                        <div className="form_input">
                            <label htmlFor="password" className='mb-1'>Confirm Password <b style={{ color: "red" }}>*</b></label>
                            <div className="two">
                                <input type={!cpassShow ? "password" : "text"} value={inputValue.cpassword} onChange={setVal} name="cpassword" id="cpassword" placeholder='********' className={(error.cpassword && "input_error") || (isValid.cpassword && "valid")} />
                                <div className="showpass" onClick={() => setCPassShow(!cpassShow)}>
                                    {!cpassShow ? <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" style={{ color: "#c10a49" }}><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" fill='#c10a49' /></svg> : <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" style={{ color: 'rgb(193, 10, 73)' }}><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" fill='#c10a49' /></svg>}
                                </div>
                            </div>
                            {error.cpassword ? <span style={{ color: "red", fontSize: "14px" }}>{error.cpassword}</span> : ""}
                        </div>

                        <button className='btn_color' onClick={Reset}>Submit</button>
                        <p>Back to <NavLink to="/"><b>Sign In</b>!</NavLink> </p>
                    </form>
                    {/* <ToastContainer />
                     */}
                </div>
            </section>
        </>
    )
}

export default ResetPassword;