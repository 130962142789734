import React, { useState } from 'react'
import { NavLink } from "react-router-dom"
import "../components/Login.css";
import Swal from 'sweetalert2';
import { ScaleLoader } from 'react-spinners';

const ForgetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [inputValue, setinputValue] = useState({
        email: "",
    });

    const [isValid, setIsValid] = useState({
        email: false,
      });

    const setValue = (e) => {
        // console.log(e.target.value);
        const { name, value } = e.target;

        setinputValue(() => {
            return {
                ...inputValue,
                [name]: value
            }
        })
        setIsValid((validState) => ({
            ...validState,
            [name]: value.trim() !== '',
        }));
    };


    const user = async(e) => {
        e.preventDefault();

        const { email} = inputValue;
        const errorMessage = {};
        console.log(email);
        if (email === "") {
            errorMessage.email = "Email is required!";
        } else if (!email.includes("@")) {
            errorMessage.email = "Email is required!";
        } 

        setError(errorMessage);
        if (Object.keys(errorMessage).length === 0) 
        {    setLoading(true);
            try{

                const data = await fetch("http://localhost:8000/api/forgot-password",{
                    method:"POST",
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        email
                    })
                });

                const res = await data.json();
                // console.log(res);

                if(res.status === 401)
                {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: res.error,
                        text: "Please Enter Valid Data!"
                    });
                }
                else if(res.status === 202)
                {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: res.msg,
                    });
                    setinputValue({ ...inputValue, email: ""});
                }
                else if(res.status === 402)
                {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: res.error,
                        
                    });
                }
                else if(res.status === 500)
                {
                    setLoading(false);
                }
              
            }
            catch{
                setLoading(false);
                Swal.fire({
                    icon: "info",
                    title: "502 Bad Gateway"
                });
            }
        }
    }

    return (
        <>
           {loading && (
            <div className="blur-background">
              <span style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}><ScaleLoader color={"#26ADB7"} size={60} /></span>
            </div>
          )}
            <section>
                <div className="form_data d-flex flex-wrap " >
                    <form style={{width:"30%"}}>
                    <div className="form_input mb-2 ">
                        <label htmlFor="email" className='mb-1'>Email <b style={{ color: "red" }}>*</b></label>
                        <input type="email" value={inputValue.email} onChange={setValue} name="email" id="email" placeholder='john.mathiw@example.com' className={(error.email && "input_error") || (isValid.email && "valid")}
                        />
                        {error.email && <span style={{ color: "red", fontSize: "14px" }}>{error.email}</span>}
                    </div>
                        
                        <center><button className='btn_color ' onClick={user}>Submit</button></center>
                        <p>Back to <NavLink to="/"><b>Sign In</b></NavLink>!</p>
                    </form>
                   
                </div>
            </section>
        </>
    )
}

export default ForgetPassword;