import React, { useState } from 'react'

import axios from 'axios';
import Swal from "sweetalert2";
import 'bootstrap/dist/css/bootstrap.css';





const Apply = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        middlename: '',
        lastname: '',
        email: '',
        phone: '',
        gender: '',
        coursename: '',
        passedout: '',
        resume: null,
    });
    const [errors, setErrors] = useState({});

    const handlechange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    function generateYearOptions() {
        const years = [];
        for (let year = 1950; year <= 2050; year++) {
            years.push(<option key={year} value={year}>{year}</option>);
        }
        return years;
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        if (files.length > 0) {

            console.log("Selected File:", files[0].name);
            setFormData((prevData) => {
                return {
                    ...prevData,
                    [name]: files[0],
                };
            });
        }
    };

    function generateCourseOptions() {
        const courses = [
            // Undergraduate Courses
            { value: 'Bachelor of Technology (B.Tech)', label: 'Bachelor of Technology (B.Tech)' },
            { value: 'Bachelor of Engineering (B.E)', label: 'Bachelor of Engineering (B.E)' },
            { value: 'Bachelor of Architecture (B.Arch)', label: 'Bachelor of Architecture (B.Arch)' },
            { value: 'Bachelor of Science (B.Sc)', label: 'Bachelor of Science (B.Sc)' },
            { value: 'Bachelor of Commerce (B.Com)', label: 'Bachelor of Commerce (B.Com)' },
            { value: 'Bachelor of Business Administration (BBA)', label: 'Bachelor of Business Administration (BBA)' },
            { value: 'Bachelor of Computer Applications (BCA)', label: 'Bachelor of Computer Applications (BCA)' },
            { value: 'Bachelor of Design (B.Des)', label: 'Bachelor of Design (B.Des)' },
            { value: 'Bachelor of Fine Arts (BFA)', label: 'Bachelor of Fine Arts (BFA)' },
            { value: 'Bachelor of Laws (LLB)', label: 'Bachelor of Laws (LLB)' },
            { value: 'Bachelor of Pharmacy (B.Pharma)', label: 'Bachelor of Pharmacy (B.Pharma)' },
            { value: 'Bachelor of Science in Nursing (B.Sc Nursing)', label: 'Bachelor of Science in Nursing (B.Sc Nursing)' },
            { value: 'Bachelor of Hotel Management (BHM)', label: 'Bachelor of Hotel Management (BHM)' },
            { value: 'Bachelor of Physiotherapy (BPT)', label: 'Bachelor of Physiotherapy (BPT)' },
            { value: 'Bachelor of Education (B.Ed)', label: 'Bachelor of Education (B.Ed)' },
            { value: 'Bachelor of Commerce (Honours)', label: 'Bachelor of Commerce (Honours)' },
            { value: 'Bachelor of Arts (BA)', label: 'Bachelor of Arts (BA)' },
            { value: 'Bachelor of Science in Agriculture (B.Sc Agriculture)', label: 'Bachelor of Science in Agriculture (B.Sc Agriculture)' },

            // Postgraduate Courses
            { value: 'Master of Technology (M.Tech)', label: 'Master of Technology (M.Tech)' },
            { value: 'Master of Engineering (M.E)', label: 'Master of Engineering (M.E)' },
            { value: 'Master of Architecture (M.Arch)', label: 'Master of Architecture (M.Arch)' },
            { value: 'Master of Science (M.Sc)', label: 'Master of Science (M.Sc)' },
            { value: 'Master of Commerce (M.Com)', label: 'Master of Commerce (M.Com)' },
            { value: 'Master of Business Administration (MBA)', label: 'Master of Business Administration (MBA)' },
            { value: 'Master of Computer Applications (MCA)', label: 'Master of Computer Applications (MCA)' },
            { value: 'Master of Design (M.Des)', label: 'Master of Design (M.Des)' },
            { value: 'Master of Arts (MA)', label: 'Master of Arts (MA)' },
            { value: 'Master of Laws (LLM)', label: 'Master of Laws (LLM)' },
            { value: 'Master of Pharmacy (M.Pharma)', label: 'Master of Pharmacy (M.Pharma)' },
            { value: 'Master of Science in Nursing (M.Sc Nursing)', label: 'Master of Science in Nursing (M.Sc Nursing)' },
            { value: 'Master of Hotel Management (MHM)', label: 'Master of Hotel Management (MHM)' },
            { value: 'Master of Physiotherapy (MPT)', label: 'Master of Physiotherapy (MPT)' },
            { value: 'Master of Education (M.Ed)', label: 'Master of Education (M.Ed)' },
            { value: 'Master of Fine Arts (MFA)', label: 'Master of Fine Arts (MFA)' },
            { value: 'Master of Social Work (MSW)', label: 'Master of Social Work (MSW)' },
            { value: 'Others', label: 'Others' },
        ];

        return courses.map(course => (
            <option key={course.value} value={course.value}>{course.label}</option>
        ));
    }
    function generateCountryCodeOptions() {
        const countryCodes = [
            { code: '+91' },
            { code: '+1' },
            { code: '+44' },
            { code: '+64' },
            { code: '+49' },
            { code: '+33' },
            { code: '+86' },
            { code: '+81' },
            { code: '+55' },
            { code: '+52' },
            { code: '+27' },
            { code: '+7' },
            { code: '+34' },
            { code: '+39' },
            { code: '+82' },
            { code: '+61' },
        ];
        return countryCodes.map((countryCode, index) => (
            <option key={index} value={countryCode.code}>{countryCode.code}</option>
        ));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();


        const validationErrors = {};
        if (!formData.name) {
            validationErrors.name = "Name is required";
        }

        if (!formData.lastname) {
            validationErrors.lastname = "Last Name is required";
        }
        if (!formData.email) {
            validationErrors.email = "Email is required";
        }
        if (!formData.phone) {
            validationErrors.phone = "Phone Number is required";
        }
        if (!formData.gender) {
            validationErrors.gender = "choose any one";
        }
        if (!formData.coursename) {
            validationErrors.coursename = "Course Name is required";
        }
        if (!formData.passedout) {
            validationErrors.passedout = "Passedout Year is required";
        }
        if (!formData.resume) {
            validationErrors.resume = "Resume is required";
        }

        setErrors(validationErrors);


        if (Object.keys(validationErrors).length === 0) {
            console.log(formData);
            setLoading(true);


            try {

                const result = await axios.post("http://localhost:8000/api/careerRegistration", formData, {
                    headers: { 'Content-Type': "multipart/form-data" },
                });

                setLoading(false);

                console.log(result);

               if (result.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Thank you for your interest in ThoughtForce !',
                        text: 'We will review your application and get back to you shortly.',
                    });

                    setTimeout(() => {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            name : '',
                            middlename: '',
                            lastname: '',
                            email: '',
                            phone: '',
                            gender: '',
                            coursename: '',
                            passedout: '',
                            resume: '',
                        }))
                    }, 2000);

                } else {
                    setLoading(false);

                    console.error('Failed to create User:', result);



                }

            }

            catch (error) {
                setLoading(false);
                console.error('Failed to create User:', error);

            }

        }
    }
    return (
        <>
         
            <div className='container'>

                <h2 className='text-center mt-5 pt-5'>Start your career life today!</h2>
                <form className='p-2  mb-5' onSubmit={handleSubmit} enctype="multipart/form-data">
                    <div className='row '>

                        <div className='col-md-4 mt-4'>
                            <b> <label >Name</label></b>
                            <input type='text' id='name' name='name' placeholder="First Name" value={formData.name} onChange={handlechange} className={errors.name ? 'form-control error mt-1' : 'form-control mt-1'} />
                            {errors.name && <span className='text-danger error-message'>{errors.name}</span>}
                        </div>

                        <div className='col-md-4 mt-4 '>
                            <b> <label> </label></b>
                            <input type='text' id='middlename' name='middlename' placeholder="Middle Name" value={formData.middlename} onChange={handlechange} className={errors.middlename ? 'form-control error mt-1' : 'form-control mt-1'} />
                            {errors.middlename && <span className='text-danger error-message'>{errors.middlename}</span>}
                        </div>

                        <div className='col-md-4 mt-4'>
                            <b> <label> </label></b>
                            <input type='text' id='lastname' name='lastname' placeholder="Last Name" value={formData.lastname} onChange={handlechange} className={errors.lastname ? 'form-control error mt-1' : 'form-control mt-1'} />
                            {errors.lastname && <span className='text-danger error-message'>{errors.lastname}</span>}
                        </div>
                    </div>

                    <div className='row '>
                        <div className='col-md-4 mt-4'>
                            <b><label>E-Mail ID</label></b>
                            <input type='email' id='email' name='email' placeholder='example@gmail.com' value={formData.email} onChange={handlechange} className={errors.email ? 'form-control error mt-1' : 'form-control mt-1'} />
                            {errors.email && <span className='text-danger error-message'>{errors.email}</span>}
                        </div>

                        <div className='col-md-4 mt-4'>
                            <b><label>Phone Number</label></b>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select id="countryCode" name="countryCode" value={formData.countryCode} onChange={handleChange} className="form-control mt-1">
                                        {generateCountryCodeOptions()}
                                    </select>
                                </div>
                                <input type='text' pattern="[0-9]{10}" id='phone' name='phone' value={formData.phone} onChange={handleChange} className={errors.phone ? 'form-control error mt-1' : 'form-control mt-1'} />
                            </div>
                            {errors.phone && <span className='text-danger error-message'>{errors.phone}</span>}
                        </div>

                        <div className='col-md-4 mt-4'>
                            <b><label>Gender</label></b><br />
                            <input type='radio' name='gender' id='gender' value="Male" onChange={handlechange} className={errors.gender ? 'form-check-input error mt-1' : 'form-check-input mt-1'} />
                            &nbsp; <label>Male</label>
                            &nbsp; &nbsp;<input type='radio' name='gender' id='gender' value="Female" onChange={handlechange} className={errors.gender ? 'form-check-input error mt-1' : 'form-check-input mt-1'} />
                            &nbsp; <label>Female</label>
                            &nbsp; &nbsp;<input type='radio' name='gender' id='gender' value="Others" onChange={handlechange} className={errors.gender ? 'form-check-input error mt-1' : 'form-check-input mt-1'} />
                            &nbsp;<label>Other</label><br />
                            {errors.gender && <span className='text-danger error-message'>{errors.gender}</span>}
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-md-4 mt-4'>
                            <b><label>Course Name</label></b>
                            <select id='coursename' name='coursename' value={formData.coursename} onChange={handleChange} className={errors.coursename ? 'form-control error mt-1' : 'form-control mt-1'}>
                                <option value="">Select your course</option>
                                {generateCourseOptions()}
                            </select>
                            {errors.coursename && <span className='text-danger error-message'>{errors.coursename}</span>}
                        </div>


                        <div className='col-md-4 mt-4'>
                            <b><label>Passedout Year</label></b>
                            <select id='passedout' name='passedout' value={formData.passedout} onChange={handleChange} className={errors.passedout ? 'form-control error mt-1' : 'form-control mt-1'}>
                                <option value="">Select passedout year</option>
                                {generateYearOptions()}
                            </select>
                            {errors.passedout && <span className='text-danger error-message'>{errors.passedout}</span>}
                        </div>


                        <div className='col-md-4 mt-4'>
                            <b><label>Upload Resume</label></b>
                            <input type='file' id='resume' name='resume' onChange={handleFileChange} className={errors.resume ? 'form-control error mt-1' : 'form-control mt-1'} />
                            {errors.resume && <span className='text-danger error-message'>{errors.resume}</span>}
                        </div>

                    </div>


                    <div className=' d-flex flex-column align-items-center mt-5'>
                        <button type='submit' className="btn" style={{
                            backgroundColor: "#26ADB7", color: "white", width: "120px",
                           
                        }} disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
                    </div>

                </form>

            </div>
            {/* <div className='position-relative'>
                <img className='position-absolute bottom-0 left-0'alt='' width="120px" />
            </div> */}
        </>
    );
};

export default Apply;