import React from "react";
import logo from "../image/thoughtforceLogo.png"
import facebook from "../image/facebook.png";
import twitter from "../image/twitter.png";
import instagram from "../image/instagram.png";
import linkedin from "../image/linkedin.png";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {

    const handleClick = () => {
        window.scrollTo(0, 0);
    }
    return (
        <footer >

            <div className="px-5" style={{ fontFamily: "Noto Sans" }}>
                <hr style={{ color: "#26ADB7" }}></hr>
                <div className=" Style ">
                    <div className="row pt-5  ">
                        <div className="d-flex justify-content-around ">
                            <div className="col-md-3 mb-5">
                                <NavLink className="Link" to="/" onClick={handleClick} > <img src={logo} alt="log" width="55%" /> </NavLink>
                                <p className="mt-3" style={{ textAlign: "justify", marginTop: "-5%" }}> Welcome to ThoughtForce. We are a team of experienced professionals who are dedicated to helping our clients succeed through the effective use of technology.</p>

                            </div>
                            <div className="col-md-1">
                            </div>
                            <div className="col-md-3 d-flex flex-column gap-2 text-left mb-5">
                                <h4 className="" >QUICK LINK</h4>
                                <Link className="Link" to="/aboutus" onClick={handleClick} >About Us</Link>
                                <Link className="Link" to="/service" onClick={handleClick} >Services</Link>
                                <Link className="Link" to="/career" onClick={handleClick} >Career</Link>
                                <Link className="Link" to="/contact" onClick={handleClick} >Contact Us</Link>
                            </div>
                            <div className="col-md-3 d-flex flex-column gap-2 text-left mb-5">
                                <h4 className="" >OUR SERVICES</h4>
                                <Link className="Link" to="/service" onClick={handleClick} >Technical Experties Development</Link>
                                <Link className="Link" to="/service" onClick={handleClick}>Software Development </Link>
                                <Link className="Link" to="/service" onClick={handleClick}>Product & Application Testing </Link>
                            </div>
                            {/* <div className="col-md-2 text-left mb-5">
                            <h4 className="">CONNECT US</h4>
                            <div className="social-as d-flex gap-3 mt-3">
                                <li className="list-unstyled" style={{ width: "auto", padding: "5px" }}><img src={facebook} alt="facebook" width="100%"></img></li>
                                <li className="list-unstyled" style={{ width: "auto", padding: "5px" }}><img src={twitter} alt="twitter" width="100%"></img></li>
                                <li className="list-unstyled" style={{ width: "auto", padding: "5px" }}><img src={instagram} alt="instagram" width="100%"></img></li>
                                <li className="list-unstyled" style={{ width: "auto", padding: "5px" }}><img src={linkedin} alt="linkedin" width="100%"></img>  </li>
                            </div>

                        </div> */}

                        </div>

                    </div>
                    <div className="d-flex justify-content-center">
                        <p> &copy; Copyright 2024 | ThoughtForce LLC |  All Rights Reserved</p>
                    </div>
                </div>
            </div>

        </footer>






    );

}




export default Footer;