
import './App.css';
import Home from './pages/Home';
import ForgetPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import { Career } from './pages/Careers';
import Apply from './pages/CareerRegister';
import AboutUs from './pages/AboutUs';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Service from './pages/Service';
import  ResponsiveAppBar from './components/NavBar';

function App() {
  return (
    <div>
      <div className='background1'></div>
      <div className='background2'></div>
      <BrowserRouter>
      {/* <Header/> */}
      <ResponsiveAppBar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/aboutus' element={<AboutUs/>}/>
        <Route path='/career' element={<Career/>}/>
        <Route path='/service' element={<Service/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/forgotpassword' element={<ForgetPassword/>}/>
        <Route path='/resetpassword/:id/:token' element={<ResetPassword/>}/>
        <Route path="/apply" element={<Apply/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
