import React from 'react'
import training from '../image/Technical training.svg';
import development from '../image/Software Developement.svg';
import testing from "../image/testing.png";
import './Service.css';


export default function Service() {
    return (
        <>
            <div className='Main' style={{ overflow: 'hidden' }}>
                <div className='row' style={{ marginBottom: "-2%" }}>
                    <div className='col-md-12 servicebackground serviceframe'>
                        <div className='row'>
                            <div className='col-md-6'></div>
                            <div className='col-md-6'>
                                {/* <h1 className='' style={{ marginTop: '35px', textAlign: "center" }}> OUR SERVICES</h1> */}
                            </div>
                        </div>

                    </div>
                </div>
                <div className='row px-5 d-flex justify-content-center align-items-center' style={{ marginTop: "0%" }}>

                    <div className='col-md-6  text-center'>
                        <img src={training} width='85%' alt='Logo not found' />
                    </div>
                    <div className='col-md-6 mb-3 px-5 '>
                        <h1 className='mb-3' >Technical Expertise Development (TED)</h1>
                        <p style={{ textAlign: 'justify' }}>Embark on an immersive and life-changing technical voyage guided by renowned industry leaders. Our all-encompassing program delves into the latest and most innovative subjects, providing you with hands-on expertise and invaluable real-world knowledge. Experience tailored mentorship and thrive in a cooperative ecosystem, allowing you to unleash your full potential and elevate your professional journey as a pioneering force in the world of technology.</p>
                    </div>
                </div>

                <div className=' row px-5 d-flex justify-content-center align-items-center' style={{ marginTop: "-5%" }}>
                    <div className='col-md-6 mb-3 px-5' style={{ textAlign: 'justify' }}>
                        <h1 className='mb-3' >Software Development</h1>
                        <p >Elevate your business with cutting-edge software development, a cornerstone for staying competitive. Our seasoned team of developers excels in crafting bespoke desktop, web, and mobile applications, precisely tailored to your requirements. We take pride in architecting distinct software solutions to drive your business towards unprecedented success. Leave behind antiquated systems, and connect with us today to explore how our software development expertise can fuel your company's growth.
                        </p>
                        
                    </div>
                    <div className='col-md-6 mb-3 text-center'>
                        <img src={development} width='83%' alt='Logo not found' />
                    </div>
                </div>
                <div className=' row px-5 d-flex justify-content-center align-items-center' style={{ marginTop: "-3%" }}>
                    <div className='col-md-6 mb-3 text-center'>
                        <img src={testing} width='83%' alt='Logo not found' />
                    </div>
                    <div className='col-md-6 mb-3 px-5'>
                        <h1 className='mb-3' >Product and Application Testing (PAT)</h1>
                        <p style={{ textAlign: 'justify' }} >Our comprehensive testing services cover both products and applications, ensuring they meet the highest standards of quality, functionality, and performance. Whether it's software or hardware, our expert team conducts thorough assessments to identify and address any potential issues, guaranteeing a seamless user experience. Utilizing industry-leading methodologies and tools, we verify the functionality, usability, and compatibility of your applications, optimizing them for performance across various platforms and devices. With our rigorous testing process, you can release your products and applications with confidence, knowing they have been meticulously evaluated and optimized to meet the demands of your users.
                        </p>
                    </div>

                </div>

                <div class="dd_heading">

                </div>
                <div className='container my-4'>
                    <main class="row d-flex justify-content-around">
                        <div className='col-md-3 mb-3'>
                            <div class="d_card d_card1">
                                <div class="servicecontent">
                                    <h2 class="heading mt-4">Desktop Application</h2>
                                    <p class="data-servicecontent">We excel in crafting custom desktop apps, including OS, productivity tools, and business software. Our expertise ensures seamless and efficient solutions for your unique needs. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>

                            <div class="d_card d_card2">
                                <div class="servicecontent">
                                    <h2 class="heading mt-4">Web Application</h2>
                                    <p class="data-servicecontent">Boost digital success with our tailored web apps. Expertise in tech and design ensures responsive solutions. Partner for achievement.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <div class="d_card d_card3">
                                <div class="servicecontent">
                                    <h2 class="heading mt-4">Mobile Application</h2>
                                    <p class="data-servicecontent">Unleash innovation with our mobile app experts! We craft tailored, cutting-edge apps for your needs, boosting digital success in your hand!</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <div class="d_card d_card4">
                                <div class="servicecontent">
                                    <h2 class="heading mt-4">Microsoft Product Support</h2>
                                    <p class="data-servicecontent">Empower your organization and communication  with Microsoft's Power Platform—Power BI, Power Apps, and SharePoint, Microsoft's premier platform for content management, collaboration, and workflow automation.</p>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}